/**
 * Create a debounced function
 *
 * @param {Function} callback
 * @param {Integer} wait
 * @returns {Function}
 */
export function debounce(callback, wait) {
  let timeout;

  return function () {
    // Capture the context and arguments, so we can use them in the timeout
    const context = this;
    const args = arguments;

    // Remove the previous timeout
    clearTimeout(timeout);

    // Set a new timeout
    timeout = setTimeout(function () {
      timeout = null;
      callback.apply(context, args);
    }, wait);
  };
}
