import { readNotification } from "../lib/requests";
import ApplicationElement from "./application_element";

export default class UnreadNotificationElement extends ApplicationElement {
  static tagName = "unread-notification";

  afterConnected() {
    if (!this.dataset.unreadNotificationId) return;

    this.observer = new IntersectionObserver(
      this.#handleObservations.bind(this),
      { threshold: 1 },
    );
    this.observer.observe(this);
  }

  beforeDisconnect() {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.observer) this.observer.disconnect();
  }

  /**
   * Handle incoming observations from our intersection observer
   *
   * @param {IntersectionObserverEntry[]} entries
   * @param {IntersectionObserver} observer
   */
  async #handleObservations(entries) {
    const entry = entries.find((entry) => entry.target === this);
    if (entry.isIntersecting) {
      this.timeout = setTimeout(() => {
        // If the element isn't visible when this callback runs, we don't mark this as read
        if (!this.checkVisibility()) return;

        readNotification(this.dataset.unreadNotificationId);
        // We don't need to update our notification more than once, so we disconnect the observer
        this.observer.disconnect();
      }, 1000);
    }
  }
}
