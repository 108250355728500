import ApplicationElement from "./application_element";

export default class PasswordRevealElement extends ApplicationElement {
  static tagName = "password-reveal";
  static refs = ["toggle", "input"];
  static events = {
    click: { method: "toggle", ref: "toggle" },
  };

  toggle(event) {
    event.preventDefault();

    this.pressed = !this.pressed;
  }

  get pressed() {
    return this.refs.toggle.ariaPressed === "true";
  }

  set pressed(newState) {
    this.refs.toggle.ariaPressed = newState;
    this.refs.input.type = newState ? "text" : "password";
  }
}
