import ApplicationElement from "./application_element";

export default class PasswordValidatorElement extends ApplicationElement {
  static tagName = "password-validator";
  static refs = ["validations", "input"];
  static events = {
    input: { method: "checkValidations", immediate: true },
  };

  checkValidations() {
    const value = this.refs.input.value;

    [...this.refs.validations.children].forEach((element) =>
      element.setAttribute(
        "data-password-validator-valid",
        this.#isValid(element, value),
      ),
    );
  }

  #isValid(element, value) {
    const rule = element.dataset.passwordValidatorRule;
    switch (rule) {
      case "length":
        return value.length >= +element.dataset.passwordValidatorValue;
      case "characters":
        return value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/) !== null;
      case "special_character":
        return value.match(/[!@#$%^&*)(+=._-]/) !== null;
    }
  }
}
