import { debounce } from "../lib/debounce";
import { AIFeedback } from "../lib/requests";
import ApplicationElement from "./application_element";

export default class ContentValidatorElement extends ApplicationElement {
  static tagName = "content-validator";
  static refs = ["target"];
  static events = {
    input: { method: "validate" },
  };
  #abortController;
  #domParser = new DOMParser();

  // We only validate with a debounce, to put less strain on our API
  validate = debounce(this.#validate, 400);

  async #validate(event) {
    this.refs.target.innerHTML = "";

    // Abort any ongoing requests
    if (this.#abortController) this.#abortController.abort();

    // Get name of model and attribute. Note that this only works with our current format of input names
    const [, model_name, attribute_name] = event.target.name.match(
      /([a-z_]*)\[([a-z_]*)\]/,
    );

    this.#abortController = new AbortController();
    const text = await AIFeedback(
      model_name,
      attribute_name,
      event.target.value,
      this.#abortController.signal,
    );

    if (text) {
      const doc = this.#domParser.parseFromString(text, "text/html");
      // Since we parse the document, it is wrapped in a html and body tag.
      // We only care about the content of the first element, so we select the first element
      // and then swap the innerHTML
      this.refs.target.innerHTML = doc.querySelector("body > *").innerHTML;
    }
  }
}
