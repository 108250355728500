import ApplicationElement from "./application_element";

// This element clicks a hidden button inside the element when the user clicks the element.
export default class AutosubmitForm extends ApplicationElement {
  static tagName = "autosubmit-form";
  static refs = ["button"];
  static events = {
    change: "handleChange",
  };

  handleChange() {
    this.refs.button.click();
  }
}
